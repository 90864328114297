import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Image from "../components/Image";
import story1 from "../images/story-1.png";
import story2 from "../images/story-2.png";
import story3 from "../images/story-3.png";
import story4 from "../images/story-4.png";
import spot from "../images/spot.png";
import "../styles/story.scss";
import { useEffect } from "react";

export const Story = () => {
  useEffect(() => {
    let story1: HTMLElement = document.getElementById("story1")!;
    let story2 = document.getElementById("story2")!;
    let story3 = document.getElementById("story3")!;
    let story4 = document.getElementById("story4")!;
    let count = 1;
    const interval = setInterval(() => {
      console.log("count", count);
      if (count == 1) {
        story1.style.opacity = "1";
        story2.style.opacity = "0";
        story3.style.opacity = "0";
        story4.style.opacity = "0";
        count++;
      } else if (count == 2) {
        story1.style.opacity = "1";
        story2.style.opacity = "1";
        story3.style.opacity = "0";
        story4.style.opacity = "0";
        count++;
      } else if (count == 3) {
        story1.style.opacity = "1";
        story2.style.opacity = "1";
        story3.style.opacity = "1";
        story4.style.opacity = "0";
        count++;
      } else if (count == 4) {
        story1.style.opacity = "1";
        story2.style.opacity = "1";
        story3.style.opacity = "1";
        story4.style.opacity = "1";
        count++;
      } else {
        story1.style.opacity = "1";
        story2.style.opacity = "0";
        story3.style.opacity = "0";
        story4.style.opacity = "0";
        count = 2;
      }
    }, 1500);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <div className="story-container" id="story">
        <img src={spot} className="story-spot" alt="" />
        <div className="row">
          <div className="col-lg-6">
            <p className="subHeading">about</p>
            <p className="heading">NEKOMIMI</p>
            <p
              className="content"
              style={{ textAlign: "start", textAlignLast: "start" }}
            >
              Nekomimi Kat Club enriches you with an expansive, yet limited
              collection of 10,000 cat-like humanoid aliens that encapsulates
              the 3D digital avatars of the extensive femininity with a feline
              touch. Each Nekomimi is unique and programmatically generated from
              over 350 possible traits, including facial expression, clothing,
              hairstyle and more.
              <br /> Nekomimis are stored as ERC-721 tokens on the Ethereum
              blockchain and hosted on IPFS. <br />
              Our goal is to bridge NFTs with real-world utility, web3
              engagement and social experiences that Is made possible by our
              community.
            </p>
          </div>
          <div className="col-lg-6 d-flex">
            <img
              id="story1"
              src={story1}
              className="story-img"
              style={{ animationDelay: "0s" }}
            ></img>
            <img
              id="story2"
              src={story2}
              className="story-img"
              style={{ marginLeft: "-10rem", animationDelay: "1s" }}
            ></img>
            <img
              id="story3"
              src={story3}
              className="story-img"
              style={{ marginLeft: "-10rem", animationDelay: "2s" }}
            ></img>
            <img
              id="story4"
              src={story4}
              className="story-img"
              style={{ marginLeft: "-10rem", animationDelay: "3s" }}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};
export default Story;
