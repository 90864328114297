import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Navbar } from "react-responsive-navbar-overlay";
import Image from "../components/Image";
import ReadMoreReact from "read-more-react";
import catsm from "../images/cat-sm.png";
import cat2 from "../images/cat2.png";
import cat2Glow from "../images/catsm-glow.png";
import catGlow from "../images/cat2-glow.png";
import aboutbg from "../images/aboutus-bg.png";
import cat from "../images/cat.png";
import catminiglow from "../images/cat-mini-glow.png";
import { useCallback } from "react";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const aboutBgStyle = {
  width: "100%",
  height: "100%",
  position: "absolute",
  zIndex: 0,
  top: 0,
  left: 0,
  objectFit: "cover",
};
const minLength = 500;
const idealLength = 500;
const maxLength = 2000;
const content = `Reeling in the Metaverse, get ready to witness the year 2098 in all
its entirety. The ultimate transformation and bonding among the
metaverse and the existing humanoid robots have been initiated.
Experience a connection dwelled in, in the past, with an
unanticipated magnitude of relativity. Communicate and unravel the
mysterious shape-shifting characteristics of a powerful creature
with enhanced self-reliance and wholesome spirits. For thousands of
years, felines or ‘cats’ of all nature, form and kind, have been
associated with the powerful feminine gender. Ancient stories
revolve around their seamless link with their unique avatar. Their
independence, and self-taming abilities have rendered a mystic
disconnect with the humankind. Tygra, was once a flourishing planet,
also considered as the epitome of an ideal homeland. Being dispersed
by unimaginable decomposition, it treaded on the path of becoming
nothing, but, a dying planet. To protect themselves, the inhabitants
fled in search of a tantamount planet for flourishing their
livelihood, and survival. Galaxies didn’t restrict their quest to
find what the perfect haven would look and feel like. With stories
of being transcended into the lush green, and iconic blue ball was
what made them identify their route to the Milky Way Galaxy. With
time, they rebuilt themselves and rekindled with the metaverse and
their presence. The Nekomimis, cat-like humanoid aliens, perceived
the fate that Earth was looming towards, and did not wish to watch
another Tygra replicating itself. Hence, with an astute and
definitive mindset, their unquestionable decision to help save the
Earth from depleting to nothingness stood ground.`;
export const catStyle = {
  width: "100%",
  objectFit: "contain",
};
export const About = () => {
  return (
    <div className="about-container " id="about">
      <img src={aboutbg} style={aboutBgStyle} />

      <div
        className="row"
        style={{
          paddingTop: "4rem",
          margin: 0,
          zIndex: 10,
          position: "relative",
        }}
      >
        <div className="col-lg-3">
          <div style={{ padding: "0 15%" }} className="cat-img1">
            <img
              src={cat}
              onMouseOver={(e) => (e.currentTarget.src = catGlow)}
              onMouseOut={(e) => (e.currentTarget.src = cat)}
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 p-4">
          <p className="subHeading">Lore</p>
          <p className="heading">The Story</p>
          <p className="content">
            <ReadMoreReact
              text={content}
              min={minLength}
              ideal={idealLength}
              max={maxLength}
              readMoreText="Read More"
            />
          </p>
        </div>
        <div className="col-lg-3 ">
          <div style={{ padding: "0 15%" }} className="cat-img1">
            <img
              src={cat2}
              onMouseOver={(e) => (e.currentTarget.src = cat2Glow)}
              onMouseOut={(e) => (e.currentTarget.src = cat2)}
              alt=""
            />
          </div>
          <div style={{ padding: "0 15%" }} className="cat-sm">
            <img
              src={catsm}
              onMouseOver={(e) => (e.currentTarget.src = catminiglow)}
              onMouseOut={(e) => (e.currentTarget.src = catsm)}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
