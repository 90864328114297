import * as React from "react";
import "../styles/timeline.scss";
import { useIntersection } from "../components/useIntersection";
import Image from "../components/Image";
export const Timeline = () => {
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const ref6 = React.useRef();
  const ref7 = React.useRef();
  const ref8 = React.useRef();
  //================
  const inView1 = useIntersection(ref1, "-100px");
  const inView2 = useIntersection(ref2, "-100px");
  const inView3 = useIntersection(ref3, "-100px");
  const inView4 = useIntersection(ref4, "-100px");
  const inView5 = useIntersection(ref5, "-100px");
  const inView6 = useIntersection(ref6, "-100px");
  const inView7 = useIntersection(ref7, "-100px");
  const inView8 = useIntersection(ref8, "-100px");

  if (inView1) {
    console.log("in view");
  }
  return (
    <div className="timeline">
      <div className="containert left">
        <div
          className={inView1 ? "active contentt" : "disable contentt"}
          ref={ref1 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">PHASE 1</div>
          <p className="timeline-heading">The Launch </p>
          <p className="timeline-des">
            Virtual events where we invite our community to learn about our
            vision for art on the blockchain. Follow our twitter and discord for
            more updates. Partnership announcements and giveaways with special
            roles and whitelist benefits. Launch our genesis collection of
            10,000 Nekomimi’s into the Metaverse.
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert right">
        <div
          className={inView2 ? "active contentt" : " disable contentt"}
          ref={ref2 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">PHASE 2</div>
          <p className="timeline-heading"> Rewarding The Community</p>
          <p className="timeline-des">
            Your support means the world to us and we want to thank you and
            recognise you for trusting our vision. To show our appreciation we
            will be airdropping surprise NFTs from our treasury to 10 early
            adopters. We not only want to infiltrate your digital world, we want
            your friends and family to enjoy Nekomimi Kat Club art too! 10
            collectors will also have the opportunity to win physical prints of
            their NFT to hang up in their homes or gift to a friend! We intend
            for the Neko’s private Discord to be the go-to spot for breaking
            news and allowlist spots for future bluechips and curated airdrops
            in collaboration with top artists. All Holders will be automatically
            whitelisted for our next drop via snapshot.
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert left">
        <div
          className={inView3 ? "active contentt" : " disable contentt"}
          ref={ref3 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">PHASE 3</div>
          <p className="timeline-heading">Merchandise</p>
          <p className="timeline-des">
            The Nekomimi Kat Club Brand official Merchandise store will be open
            to all, but only members will have access to limited edition
            merchandise and priority access to all drops.
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert right">
        <div
          className={inView4 ? "active contentt" : " disable contentt"}
          ref={ref4 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">PHASE 4</div>
          <p className="timeline-heading">Charity </p>
          <p className="timeline-des">
            Activate and Fund a community wallet for Charity. The Nekomimi Kat
            Club members will have voting powers to decide upon which charities
            will be considered. 
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert left">
        <div
          className={inView5 ? "active contentt" : " disable contentt"}
          ref={ref5 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">Phase 5 </div>
          <p className="timeline-heading">Lets Celebrate </p>
          <p className="timeline-des">
            Nekomimi Kat Club members will have voting rights to choose and
            empower women in the field of music, art, fashion, blockchain
            technology and culture. Members will be invited to join these events
            (virtually &amp; in-person where possible).
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert right">
        <div
          className={inView6 ? "active contentt" : " disable contentt"}
          ref={ref6 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">Phase 6 </div>
          <p className="timeline-heading">Game Development</p>
          <p className="timeline-des">
            All members will have the opportunities to provide early feedback on
            the game. Beta version of the game will be available by Q4 of 2023.
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert left">
        <div
          className={inView7 ? "active contentt" : " disable contentt"}
          ref={ref7 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">Phase 7 </div>
          <p className="timeline-heading"> Staking and Utility token</p>
          <p className="timeline-des">
            Launch of Nekomimi Kat Club’s very own utility token and staking
            platform for your NFT.
          </p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
      <div className="containert right">
        <div
          className={inView8 ? "active contentt" : " disable contentt"}
          ref={ref8 as React.RefObject<HTMLDivElement>}
        >
          <div className="badge">Phase 8 </div>
          <p className="timeline-heading">The Next Episode </p>
          <p className="timeline-des">???</p>
          <div className="timeline-icons d-flex">
            <Image
              src="Discord.png"
              style={{ height: "1.5rem", width: "1.5rem", marginRight: "1rem" }}
            ></Image>
            <Image
              src="Twitter.png"
              style={{ height: "1.5rem", width: "1.5rem" }}
            ></Image>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Timeline;
