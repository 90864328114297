import * as React from "react";
import "../styles/collections.scss";
import { useIntersection } from "../components/useIntersection";
import Image from "../components/Image";

export const Collections = () => {
  let cards = [
    {
      title: "Name of NFT",
      img: "c1.png",
    },
    {
      title: "Name of NFT",
      img: "c2.png",
    },
    {
      title: "Name of NFT",
      img: "c3.png",
    },
    {
      title: "Name of NFT",
      img: "c4.png",
    },
    {
      title: "Name of NFT",
      img: "c5.png",
    },
    {
      title: "Name of NFT",
      img: "c6.png",
    },
    {
      title: "Name of NFT",
      img: "c7.png",
    },
    {
      title: "Name of NFT",
      img: "c8.png",
    },
  ];
  const mountain = {};
  const spot = {
    width: "100%",
    zIndex: 2,
    objectFit: "contain",
    width: "100%",
    height: "100%",
    opacity: "1",
  };
  return (
    <>
      <div id="collection">
        <div className="collection-header">
          <div className="mountain"></div>
          <div className="stars"></div>
          <div className="spot"></div>
        </div>

        <div className="collection-container">
          <div className="collection">
            {/* <p className="subHeading">CHECk OUT THE</p> */}
            <p className="heading">Sneak Peek</p>
            <div className="row" style={{ marginTop: "4rem" }}>
              {cards.map((card) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <div className="collection-card">
                    {/* <div className="collection-card-header">{card.title}</div> */}
                    <div className="collection-image">
                      <Image src={card.img}></Image>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Collections;
