import * as React from "react";
import "../styles/roadmap.scss";
import Image from "../components/Image";
import { Timeline } from "./timeline";
const stars = {
  width: "100%",
  position: "absolute",
  zIndex: 0,
  objectFit: "cover",
};
export const Roadmap = () => {
  return (
    <div className="roadmap-container" id="roadmap">
      <Image src="roadmap-bg.png" style={stars}></Image>
      <div style={{ padding: "4rem 10%" }}>
        {/* <p className="subHeading">OUR SOLID</p> */}
        <p className="heading">THE MINDMAP</p>
        <Timeline></Timeline>
      </div>
    </div>
  );
};
export default Roadmap;
